*,
*::before,
*::after {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html {
	height: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body {
	background-color: #f4f6f8;
	height: 100%;
}

a {
	text-decoration: none;
}

#root {
	height: 100%;
}

// Google maps autocomplete dropdown
.pac-container {
	z-index: 9999;
}

// Arabic fonts

@font-face {
	font-weight: 300;
	font-family: 'Hanimation Arabic';
	src: url('../fonts/Hanimation_Arabic_Light.otf');
}

@font-face {
	font-weight: 400;
	font-family: 'Hanimation Arabic';
	src: url('../fonts/Hanimation_Arabic_Regular.otf');
}

@font-face {
	font-weight: 500;
	font-family: 'Hanimation Arabic';
	src: url('../fonts/Hanimation_Arabic_Medium.otf');
}

@font-face {
	font-weight: 600;
	font-family: 'Hanimation Arabic';
	src: url('../fonts/Hanimation_Arabic_SemiBold.otf');
}

@font-face {
	font-weight: 700;
	font-family: 'Hanimation Arabic';
	src: url('../fonts/Hanimation_Arabic_Bold.otf');
}
